@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0.5rem 1rem;
  font-family: "Josefin Sans", sans-serif;
  overflow: overlay;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
}

html {
  scroll-behavior: smooth;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  /* border: 1px solid blue; */
}
